<template>
  <div class="video-box">
  </div>
</template>

<script setup></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.video-box {
  width: 80%;
  margin-left: 10%;
}

.video-img {
  width: 100%;
  margin-top: 1rem;
}

.video-heads {
  position: relative;
  z-index: 9999;
  width: 10rem;
  height: 6rem;
}

.video-border {
  width: 16rem;
  height: 4.5rem;
  position: relative;
  top: -5.6rem;
  left: -1rem;
}

/* @media screen (min-width: ) {
} */
</style>
