<template>
  <div class="box-content">
    <div class="box-content-box">
      <div class="connect-title-box">
        <div class="connect-title"><img class="question-box" src="../assets/question.png"/></div>
        <div class="connect-title connect-title-center">Connect Wallet</div>
        <div class="close-btn"><img @click="closeAction" class="question-box" src="../assets/close.png"/></div>
      </div>
      <div class="qianbao-boss">
        <div
          class="qianbao-box"
          v-for="(item, index) in imageList"
          :key="index"
        >
          <div class="WalletConnec-image-box">
            <img class="WalletConnec-image" :src="item.image" /><span
              class="WalletConnec-title"
              >{{ item.title }}</span
            >
          </div>
          <div class="qianbao-box-right">
            <div class="code-box">QR CODE</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mask-show" @click="closeAction"></div>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick } from "vue";
import { defineEmits } from "vue";
const emit = defineEmits(["updateData"]);
const imageList = ref();
imageList.value = [
  {
    image: require("../assets/Wallet-Connect.png"),
    title: "WalletConnec",
  },
  {
    image: require("../assets/metamask.png"),
    title: "MetaMask",
  },
  {
    image: require("../assets/trust-wallet.png"),
    title: "Trust Walle",
  },
  {
    image: require("../assets/wallet-link.png"),
    title: "Coinbase",
  },
  {
    image: require("../assets/more.png"),
    title: "All Wallet",
  },
];

const closeAction = () => {
  emit('closeAction')
};

</script>

<style scoped>
.question-box{
  width: 0.2rem;
}
.close-btn{
  cursor: pointer;
}
.code-box {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-style: normal;
  text-rendering: optimizespeed;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  color: #47A1FF;
  background-color: #243240;
  backface-visibility: hidden;
  margin-right: 0.1rem;
  padding: 0.05rem;
  border-radius: 0.2rem;
}
.WalletConnec-image-box {
  align-items: center;
  display: inline-flex;
}
.qianbao-boss {
  margin-top: 0.3rem;
}
.WalletConnec-title,
.WalletConnec-image {
  margin-left: 0.1rem;
  width: 0.4rem;
}
.qianbao-box {
  display: grid;
  grid-template-columns: 70% 1fr;
  color: white;
  margin: 0 0.2rem;
  padding: 0.1rem 0;
  border-radius: 0.21rem;
  background-color: #1e1e1e;
  margin-top: 0.2rem;
  align-items: center;
}

.qianbao-box-right {
  display: inline-flex;
  justify-content: flex-end;
}
.connect-title-center {
  position: absolute;
  width: 95%;
  text-align: center;
}
.close-btn {
  color: white;
  position: absolute;
  right: 0.2rem;
}
.connect-title-box {
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.box-content-box {
  position: absolute;
  width: 3.8rem;
  background: #191a1a;
  border-radius: 0.1em;
  z-index: 99999999;
  padding: 0.1rem 0;
  top: 2.4rem;
}
.connect-title {
  color: white;
  margin-left: 0.2rem;
  text-align: center;
}
.mask-show {
  position: fixed;
  width: 99vw;
  height: 100vh;
  background: rgba(30, 30, 30, 0.7);
  top: 0;
  left: 0;
  z-index: 99999;
}
.box-content {
  width: 100%;
  display: inline-flex;
  justify-content: center;
}
</style>
