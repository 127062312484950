<template>
  <div>
    <div class="preview-box">
      <div class="preview-box-content preview-box-content-1">
        <div class="mask-content">
          <video controls ref="video_dom" id="myVideo" style="object-fit: fill;width: 100%;" controlsList="nodownload">
            <source src="../assets/1.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="preview-box-content preview-box-content-2">
        <img class="logo2-box" src="../assets/logo2.png" />
      </div>
    </div>
    <div class="Fight-boss">
      <div class="Fight-box">
        <div class="Fight-box-left">
          <div>
            Get game dice every day through Blinks guessing and game tasks on X!
            Earn money through luck (rolling dice) and strategy (buying cards)
            in the game, defeat other players, and receive airdrop rewards!
          </div>
          <div class="Play-ARENA" @click="playGameBox">Play game</div>
        </div>
        <div class="Fight-box-right">
          <img class="fight-img" src="../assets/rightBox.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";

const options = ref({
  src: require("../assets/1.mp4"), //视频源
  poster: "", //封面
  autoPlay: true, //自动播放
  speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
});

const onPlay = (ev) => {
  console.log("播放");
};
const onPause = (ev) => {
  console.log(ev, "暂停");
};

const playGameBox = () => {
  window.location.href = "https://www.polyblink.com/?game=1";
}

const onTimeupdate = (ev) => {
  console.log(ev, "时间更新");
};
const onCanplay = (ev) => {
  console.log(ev, "可以播放");
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .d-player-wrap .d-player-control .d-control-tool {
  display: none !important;
}

::v-deep .d-player-wrap .d-player-control .d-control-progress .d-progress-bar .d-slider__runway {
  display: none !important;
}

::v-deep .d-player-state,
.d-player-input {
  display: none !important;
}

.mask-box {
  position: relative;
  z-index: 9999;
}

.mask-content::before {
  content: '';
  position: absolute;
  top: -29%;
  left: -42%;
  width: 600px;
  height: 150%;
  background-image: url('../assets/bk1.png');
  background-size: 100% 100%;
}

.mask-content {
  color: white;
  width: 300px;
  animation: textScroll 4s infinite;
  /* 设置动画为2秒钟的时间，并无限循环 */
}

@keyframes textScroll {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-5%);
  }


  100% {
    transform: translateY(0%);
  }


}



.preview-box-content {
  width: 70%;
  display: inline-flex;
  justify-content: flex-end;
}

.preview-box-content-1 {
  position: relative;
  left: 10%;
}

.preview-box-content-2 {
  position: relative;
  z-index: 999;
}

.logo2-box {
  width: 100%;
}

.fight-box-eyu {
  display: grid;
  grid-template-columns: 60% 1fr;
}

.fight-img {
  width: 5rem;
}




.Fight-box-left {
  font-size: 0.26rem;
}

.Fight-boss {
  background-color: #19191a !important;
  color: white;
  
  font-size: 0.15rem;
  align-items: center;
  margin: 0.4rem 0;
  padding: 0.5rem 0;
}

.Fight-box {
  display: grid;
  grid-template-columns: 60% 1fr;
  align-items: center;
  width: 90%;
  margin-left: 5%;
}

.Fight-box-right {
  display: inline-flex;
  justify-content: flex-end;
  border-image-source: url('../assets/bordere1.png');
  border-image-slice: 30 fill;
}

.preview-box {
  display: inline-flex;
  align-items: center;
  margin: 1rem 0;
  justify-content: center;
  width: 90%;
}

@media screen and (max-width: 750px) {
  .preview-box {
    margin-top: 40px !important;
  }


}
</style>
