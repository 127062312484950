<template>
  <div class="battle-box">
      <div class="battle-tp">To battle in the Arena!</div>
      <div class="battle-tp">Everyone starts with a BASE and works their way to Epic by battling on the Arena. Fight more, improve the NFT tier and increase the LootBox drop Lucky Chance - It's that simple!</div>
      <img class="Everyone-box" src="https://degamex.com/assets/mint-arena-e3c472c0.svg"/>
 </div> 
</template>

<script setup>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.battle-tp{
  text-align: center;
}

.battle-tp:nth-of-type(2){
  font-size: 0.2rem;
}

.battle-tp:nth-of-type(1){
  font-size: 0.4rem;
  margin-top: 0.7rem;
  margin-bottom: 0.2rem;
}
.battle-box{
  color: white;
}

.Everyone-box{
  width: 100%;
  margin-top: 0.2rem;
}

</style>
