<template>
  <div class="hello">
    <div class="FAQ-title">FAQ</div>
    <div class="Chain-boss">
      <div class="Chain-box" v-for="(item, index) in chainList" :key="index">
        <div class="Chain-title">{{ item.title }}</div>
        <div class="Chain-content">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const chainList = ref();
const create = () => {
  chainList.value = [
    {
      title: "What Chain?",
      content: "The DeGameX platform is fully built on Blast, so are NFTs.",
    },
    {
      title: "Wen NFT Reveal?",
      content: "March 4th-6th",
    },
    {
      title: "Where can I see my NFT?",
      content: "Either head to the My Account or on secondary Marketplaces.",
    },
    {
      title: "Where Can I trade My NFT?",
      content:
        "You can trade your NFT on the WEN exchange, Elements Market, or BLUR.",
    },
    {
      title: "How Can I Increase my NFT's Tier?",
      content:
        "ou can increase your NFT's tier by battling in the Arena! Everyone starts with a Base Tier and works their way up to Rare & Epic for more rewards. To achieve Rare, you will need 100 battles, and for Epic, you will need 500.",
    },
  ];
};

create();
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.FAQ-title {
  color: #D203FA;
  font-size: 0.4rem;
  margin: 0.5rem 0;
  text-align: center;
}
.Chain-box {
  background-color: #19191a;
  padding: 0.7rem 0;
  font-size: 0.2rem;
  border-radius: 0.3rem;
  text-align: center;
  margin-top: 0.5rem;
}
.hello {
  color: white;
}

.Chain-title {
  color: #D203FA;
}
</style>
