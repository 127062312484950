import { createApp } from 'vue'
import App from './App.vue'
let app = createApp(App)
import ElementPlus from 'element-plus' //全局引入
import 'element-plus/dist/index.css'
import $ from 'jquery';
import "../assets/js/rem";
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
app.use(vue3videoPlay)
app.use(ElementPlus)
app.mount('#app')
