<template>
  <div>
    <el-carousel :interval="4000" type="card" :height="cardHeight" @change="swiperLunbo">
      <el-carousel-item v-for="(item, index) in dataList" :key="item" @click="itemBox(item, index)">
        <img class="niao-box niao-box-3" :src="item.image" />
      </el-carousel-item>
    </el-carousel>

  </div>
</template>

<script setup>
import { ref, onMounted, toRef, reactive } from "vue"
const cardHeight = ref('750px')
const screenWidth = ref()
const handleResize = () => {
  screenWidth.value = window.innerWidth
  console.log('screenWidth.value', screenWidth.value);
  if (screenWidth.value < 750) {
    cardHeight.value = '300px'
  }

}

/**
 * 判断是否为移动端
 */

const isMobile = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}


onMounted(() => {
  window.addEventListener("resize", handleResize)
  if (isMobile()) {
      cardHeight.value = '300px'
    } else {
      cardHeight.value = '700px'
    }
})


const currentIndex = ref(0)
const dataList = ref([
  {
    image: require('../assets/b1.jpg'),
    href: "https://dial.to/?action=solana-action:https://www.polyblink.xyz/actions/blinks?metadata=154,100000003"
  },
  {
    image: require('../assets/b2.jpg'),
    href: "https://dial.to/?action=solana-action:https://www.polyblink.xyz/actions/blinks?metadata=151,100000003"
  },

  {
    image: require('../assets/b4.jpg'),
    href: "https://dial.to/?action=solana-action:https://www.polyblink.xyz/actions/blinks?metadata=152,100000003"
  }
])
const itemBox = (item, index) => {
  console.log('index', index);
  currentIndex.value = index
  window.location.href = item.href
}

const swiperLunbo = (index) => {
  console.log('index', index);
  currentIndex.value = index
}
</script>

<style scoped>
::v-deep .el-carousel-item {
  width: 100%;
}

::v-deep .niao-box {
  width: 70%;
  height: 100%;
  margin-left: 15%;
}



::v-deep .el-carousel__mask {
  background-color: transparent;
  margin-left: 40px;
}

::v-deep .el-carousel__indicators--horizontal {
  display: none;
}
</style>