<template>
  <div class="hello">
    <div class="DEGAMERS-title">
      <div class="bianse-box">
        <img class="eryu-gif" src="../assets/eryugif.gif" />
        <img class="biaoti2-gif" src="../assets/biaoti2.png" />
      </div>
      <div class="Publish-box">
        Publish and participate in Blinks missions! If you are a prediction
        expert, you will not only receive praise on Polyblink, but also airdrop
        profits!
      </div>
    </div>
    <!-- <img
      mode="widthFix"
      class="img-deg"
      src="https://degamex.com/assets/main-media-nfts-d5e26571.svg"
    /> -->
    <div class="rate-boss">
      <swpierBox></swpierBox>
    </div>
    <div class="deg-box">
      <div class="deg-content">
        <div>
          Publish and participate in Blinks missions! If you are a prediction
          expert, you will not only receive praise on
          <span class="color-bianse">Polyblink</span>, but also airdrop profits!
        </div>

        <div class="Base-box">
          <div class="Base-box-content" v-for="(item, index) in dataList" :key="index">
            <div></div>
            <div>{{ item.title }}</div>
            <div class="Base-image">
              <img :src="item.img" />
            </div>
          </div>
        </div>
      </div>
      <div class="Play-ARENA">MINT NOW</div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick } from "vue";
import swpierBox from "../components/swpier.vue";


const dataList = ref();
const swiperOptions = ref();
const created = () => {

  dataList.value = [
    {
      title: "",
      img: require("../../src/assets/logo2-1.png"),
    },
    {
      title: "",
      img: require("../../src/assets/btm-1.png"),
    },
    {
      title: "",
      img: require("../../src/assets/chouyan-1.png"),
    },
  ];
};

created();
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grid-image-rate {
  margin-top: 0.3rem;
}

.grid-rate-box-title {
  color: white;
  font-size: 0.2rem;
}

.bianse-box {
  color: #D203FA;
  font-size: 0.8rem;
  white-space: nowrap;
}

.Base-box-content {
  width: 30%;
  margin-left: 3%;
}



.Base-box-content:first-of-type {
  margin-left: 0 !important;
}

.Base-image {
  width: 100%;
}

.Base-image img {
  width: 100%;
}

.Base-box {
  display: inline-flex;
  width: 80%;
  margin-top: 0.5rem;
  color: white;
  font-size: 0.2rem;
}

.deg-box {
  padding: 0.8rem 0;
  background-color: #19191a;
  position: relative;
  top: 0rem;
}

.img-deg {
  width: 100%;
}

.DEGAMERS-title {
  width: 50%;
  text-align: center;
  margin-top: 0.7rem;
  color: white;
  font-size: 0.3rem;
  margin-left: 25%;
  
}

.deg-box {
  width: 100%;
  text-align: center;
  margin-top: 0.7rem;
  color: white;
  font-size: 0.2rem;
  
}

.color-bianse {
  color: #D203FA;
}

.deg-content {
  width: 90%;
  margin-left: 5%;
}

.EXTRA-btm {
  margin-top: 0.2rem;
}

.rare-box {
  flex-grow: 1;
  width: 46%;
  margin-left: 3%;
  margin-top: 0.4rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rate-boss {
  width: 100%;
  margin: 0.4rem 0;
}

.rare-box iframe {
  width: 100%;
  height: 100%;
}

.grid-rate-box {
  width: 46%;
  margin-left: 2%;
  border: 1px red solid;
  display: inline-block;
}

.grid-text {
  width: 100%;
  background-color: #D203FA;
  padding: 0.2rem 0;
  color: white;
  border-radius: 0.1rem;
  font-size: 0.2rem;
  text-align: center;
  margin-top: 0.4rem;
}

.eryu-gif {
  width: 1.2rem;
}

.biaoti2-gif {
  width: 7rem;
  position: relative;
}

@media screen and (max-width: 750px) {
  .eryu-gif {
    width: 3rem;
  }

  .biaoti2-gif {
    width: 5rem;
    margin-right: 0.5rem;
    position: relative;
    right: 0.7rem;
  }

  .bianse-box {
    width: 100%;
    display: inline-flex;
  }
}
</style>
