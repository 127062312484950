<template>
  <div>
    <div class="our-box">
      <div class="polyBlink-box">PolyBlink Terms of service</div>

      Our Terms & Conditions were last updated on 1st of November, 2023

      <div class="our-box">
        These Terms & Conditions apply to our website and its associated
        subdomains (collectively, our “Service”) alongside our application.
        These Terms & Conditions are a contract between you and DeGameX
        (referred to herein as "DeGameX", "us", "we" or "our"), the provider of
        the Service.
      </div>
      <div class="our-box">
        By accessing cookies or continuing to use our website, application,
        and/or Service, you signify that you have read, understood, and agree to
        be bound by these Terms & Conditions and our Privacy Policy. If you do
        not agree to these Terms & Conditions and/or our Privacy Policy, please
        do not use the DeGameX website.
      </div>

      <div class="our-box">
        In these Terms & Conditions, ‘you’ refers both to you as an individual
        and to an entity you represent. If you violate any of these Terms &
        Conditions, we reserve the right to cancel your account or block access
        to your account without notice.
      </div>

      <div class="our-box">DEFINITIONS AND TERMS</div>

      <div class="our-box"></div>

      <div class="our-box">
        - Agreement: acceptance of these Terms & Conditions
      </div>

      <div class="our-box">
        - Website: www.degamex.com and the pages contained therein and
        controlled by Us.
      </div>

      <div class="our-box">
        - Service: website and any content, software, data, information, and
        materials contained therein and transactions completed thereon.
      </div>

      <div class="our-box">
        - Third-party service: certain third party analysis, content, tools,
        features, and materials, refer to advertisers, contest sponsors,
        promotional and marketing partners and others who provide our content or
        whose products or services we think may interest you;
      </div>

      <div class="our-box">
        - You, Guest, User: the individual accessing or using the Service, or
        the company, or other legal entity on behalf of which such individual is
        accessing or using the Service, as applicable.
      </div>

      <div class="our-box">AGREEMENT</div>

      <div class="our-box">
        The Terms & Conditions, and any Additional Terms (collectively, the
        ‘Agreement’), set forth the terms and conditions that govern your access
        to and use of the website. The Agreement sets forth the entire, final,
        and exclusive agreement between DeGameX and you with respect to your use
        of, and access to, the Service, and supersedes all previous oral and
        written terms, representations, or understandings concerning your use
        of, and access to, this Service. This Agreement is effective between you
        and DeGameX as of the date of your acceptance of this Agreement.
      </div>

      <div class="our-box">
        By using the Service, you agree to transact electronically through the
        website. You agree that your electronic signature is the legal
        equivalent of your manual signature. You further agree that your use of
        a keypad, mouse, or other device to select an item, button, icon, or
        similar act/action constitutes your signature as if signed by you in
        writing. You also agree that no certification authority or other third
        party verification is necessary to validate your electronic signature
        and that the lack of such certification or third party verification will
        not in any way affect the enforceability of your electronic.
      </div>

      <div class="our-box">OUR PROPRIETARY RIGHTS</div>

      <div class="our-box">
        As between DeGameX and you, and subject to your right, title, and
        interest in data you submit through this Service or otherwise provide or
        submit to DeGameX, DeGameX owns all rights, title, and interest in and
        to the Service, including all related intellectual property rights
        subsisting therein. Subject to the limited rights expressly granted
        hereunder, DeGameX reserves all rights, title, and interest in and to
        the Service, including all related intellectual property rights
        subsisting therein. We grant no rights to you hereunder other than as
        expressly set forth herein. This Service and all materials and
        information published thereon are protected by Singapore and foreign
        copyright and other intellectual property laws.
      </div>

      <div class="our-box">RESTRICTIONS ON USE</div>

      <div class="our-box">
        Except as otherwise expressly provided in these Terms & Conditions or on
        the website, you may not download, modify, copy, reproduce, republish,
        post, resell, upload, transmit, or distribute any materials or content,
        or any portion thereof from or linked to the Service.
      </div>

      <div class="our-box">In addition, you agree not to:</div>

      <div class="our-box">
        - Use or access the website for any purpose that is unlawful or
        prohibited by the Agreement or to display, transmit, or otherwise make
        available on or through the Service material that is infringing,
        threatening, harassing, libelous, hateful, racially or ethnically
        objectionable, unlawful, tortious, harmful to children, invasive of
        another's privacy, or violative of third party privacy rights;
      </div>

      <div class="our-box">
        - Reverse engineer, decompile, modify, or create derivative works from
        any software or materials accessible by or on the Service;
      </div>

      <div class="our-box">
        - Frame or utilize framing techniques to enclose any trademark, logo, or
        other proprietary information (including images, text, page layout, or
        form) of DeGameX without express written consent;
      </div>

      <div class="our-box">
        - Use or access the website in a manner that could damage, disable,
        overburden, or impair any DeGameX server or the networks connected to
        any DeGameX server;
      </div>

      <div class="our-box">
        - Interfere with any third party's use and enjoyment of the Service;
      </div>

      <div class="our-box">
        - Attempt to gain unauthorized access to the Service, accounts, computer
        systems, or networks connected to any DeGameX server through hacking,
        password mining, or any other means.
      </div>

      <div class="our-box">YOUR RESPONSIBILITIES</div>

      <div class="our-box">You shall:</div>

      <div class="our-box">
        - have sole responsibility for the accuracy, quality, integrity,
        legality, reliability, and appropriateness of all content and data
        submitted to the Service by you;
      </div>

      <div class="our-box">
        - comply with all applicable laws (including, but not limited to, export
        laws) in using the Service; and
      </div>

      <div class="our-box">- use the Service solely per the Agreement.</div>

      <div class="our-box">
        Where the website permits you to create an account, You shall not
        disclose or share any ID(s) and password(s) used to access this Service.
        You are responsible for all activity that occurs under your ID(s) and
        password(s). You agree to notify DeGameX in writing promptly upon
        becoming aware of any unauthorized access or use of the website by any
        party.
      </div>

      <div class="our-box">LINKS AND THIRD PARTY INFORMATION</div>

      <div class="our-box">
        Third Party Content is not maintained or controlled by DeGameX, and as a
        matter of policy, DeGameX does not independently verify, prescreen, or
        monitor any such Third Party Content. While we believe the Third Party
        Content is from reliable third party sources, we are not responsible for
        the availability, content, completeness, adequacy, utility, or accuracy
        of such Third Party Content. DeGameX does not make any endorsement,
        express or implied, of any Third Party Content.
      </div>

      <div class="our-box">
        Certain Third Party Content is subject to additional specific Terms &
        Conditions which can be found in Additional Terms in the applicable
        areas of the website and on websites linked to this website. You
        acknowledge and agree that your use of such Third Party Content is
        subject to all these terms.
      </div>

      <div class="our-box">
        Service features that interoperate with Third Party Content depend on
        the continuing availability of such third party services and materials
        for use with the Service. If the third party providers of such services
        or materials cease to make the services or materials available on
        reasonable terms for the Services, we may cease providing such Service
        features.
      </div>

      <div class="our-box">DISCLAIMERS AND LIMITS OF LIABILITY</div>

      <div class="our-box">
        The service and the information and data on the website are provided "as
        is," without warranty of any kind, either express or implied, including,
        but not limited to, warranties of non-infringement, merchantability, or
        fitness for a particular purpose.
      </div>

      <div class="our-box">
        DeGameX shall not be liable for any direct, special, punitive,
        exemplary, indirect, incidental, or consequential damages, including,
        but not limited to, lost revenues or lost profits, whether or not
        advised of the possibility of such damage or losses and based on any
        theory of liability, arising out of or in connection with the use of the
        website, services, or any information or service on a linked website.
      </div>

      <div class="our-box">
        Specific Disclaimers. To the fullest extent possible under applicable
        law the Company specifically disclaims:
      </div>

      <div class="our-box">
        - Any warranties concerning the availability, accuracy, reliability,
        completeness, capabilities, security, timeliness, usefulness, or content
        of the website and any Services or the ability of the website or any
        Services to interoperate or integrate with any third-party products such
        as software or hardware;
      </div>

      <div class="our-box">
        - Any warranties resulting from a course of dealing or usage of trade;
      </div>

      <div class="our-box">
        - Any warranties with regard to the accuracy or completeness of or
        errors in the contents or functioning of or the accuracy of the results
        or output that derives from the use of the website or any Services;
      </div>

      <div class="our-box">
        - Any warranties of merchantability or fitness for a particular purpose;
        and
      </div>

      <div class="our-box">
        - Any warranties regarding the rights and title of the Company in the
        website and any Services, or any warranty that the website or any
        Services do not infringe the intellectual property rights of any third
        party.
      </div>

      <div class="our-box">TERM AND TERMINATION</div>

      <div class="our-box">
        In the event of any Force Majeure Event, any breach of these Terms &
        Conditions, or any other event that would make provision of the Services
        commercially unreasonable for DeGameX, we may, in our discretion and
        without liability to you, with or without prior notice, suspend your
        access to all or a portion of our Services.
      </div>

      <div class="our-box">
        We may terminate your access to the Services in our sole discretion,
        immediately and without prior notice, and delete or deactivate your
        DeGameX account (if applicable) and all related information and files in
        such account without liability to you, including, for instance, in the
        event that you breach any term of these Terms & Conditions.
      </div>

      <div class="our-box">
        We shall have no obligation to maintain or provide you a copy of any of
        your data in the Service following termination of access to and use of
        the Service.
      </div>

      <div class="our-box">FORCE MAJEURE EVENTS</div>
    </div>

    <div class="our-box">
      Any loss or damage arising from any event beyond DeGameX reasonable
      control, including but not limited to flood, extraordinary weather
      conditions, earthquake, or other act of God, fire, war, insurrection,
      riot, labor dispute, accident, action of government, communications, power
      failure, epidemic, pandemic, or equipment or software malfunction or any
      other cause beyond DeGameX reasonable control (each, a “Force Majeure
      Event”).
    </div>

    <div class="our-box">MISCELLANEOUS</div>

    <div class="our-box">
      These Terms & Conditions set forth the complete understanding between the
      users and DeGameX with respect to its subject matter, and supersedes all
      prior understanding or agreements, whether written or verbal.
    </div>

    <div class="our-box">
      If any portion of these Terms & Conditions is deemed unenforceable by a
      Court of competent jurisdiction, it shall not affect the enforceability of
      the other portions of these Terms & Conditions.
    </div>

    <div class="our-box">
      The English version shall prevail over all legal statements, and statutory
      declarations made by DeGameX, including the present Agreement. DeGameX
      does not accept any kind of legal claims, or other complaints for the
      misunderstandings as a result of any mistranslation.
    </div>

    <div class="our-box">
      These Terms & Conditions and the relations arising out from it between
      DeGameX and the users will be governed by the law of Singapore.
    </div>

    <div class="our-box">
      Any disputes arising between DeGameX and the users will be settled
      amicably and only when this solution is not efficient, the competent
      jurisdiction for the disputes arising from these Terms & Conditions will
      be the courts of Singapore.
    </div>

    <div class="our-box">CHANGES TO AGREEMENT</div>

    <div class="our-box">
      DeGameX reserves the right to make changes, modifications, amendments,
      and/or updates to this Service and the Agreement. When these changes are
      made, we will make a new copy of the Agreement available on this website.
      Changes to the Agreement shall be effective when posted. You understand
      and agree that continued use of the website after the Agreement has
      changed will be treated as your acceptance of the updated Agreement.
    </div>

    <div class="our-box">CONTACT US</div>

    <div class="our-box">
      For further inquiries or complaints about our handling of your Personal
      Data or our Privacy Policy or wish to access, update, or amend your
      Personal Data as mentioned above, please contact us via email:
      info@degamex.com.
    </div>

    <div class="our-box">Copyright © 2024 DeGameX. All Rights Reserved.</div>
  </div>
</template>

<script setup></script>

<style scoped>
.our-box {
  margin: 0.2rem 0;
  color: white;
  font-size: 0.2rem;
}

  
.polyBlink-box{color: #D203FA;font-size: 0.4rem;margin: 1rem 0;}

</style>
