<template>
  <div class="hello">
    <div class="degamex-box">
      <div class="first-box">Polyblink is Bstorm's first game that combines <span class="yellowColor">Solana Blinks</span>  and <span class="yellowColor">gamefi</span> in the guessing Monopoly genre! Win game dice by participating in Blinks guessing and play for airdrop!</div>
    </div>
  </div>
</template>

<script setup>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.degamex-box{
  margin-top: -0.6rem;
}
.degamex{
  width: 100%;
  margin-top: 1rem;
}

.first-box{
  width: 70%;
  text-align: center;
  color: white;
  font-size: 0.3rem;
  margin-left: 15%;
  
  margin-top: 1rem;

}

.yellowColor{
  color: #D203FA;
}
</style>
