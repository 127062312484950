<template>
  <div class="page-container">
    <!-- <headerBox
      @arenaAction="arenaAction"
      @conactAction="conactAction"
    ></headerBox>
    <div style="color:white"><a href="#nfts">1111</a></div> -->
    <div>
      <div class="header-title">
        <div class="header-title-left">
          <img class="header-image-logo" src="./assets/game-logo.png" />
          <div v-for="(item, index) in headerList" :key="index" :class="{ 'Mint-nft': currentIndex == index }"
            class="heaeder-text">
            <a href="#nfts" @click="qitaAction(index)" v-if="index == 1">{{
              item.title
            }}</a>
            <a @click="arenaAction(index)" v-else>{{ item.title }}</a>
          </div>

          <img class="header-image" @click="xAction" src="./assets/xBox.png" />
          <img class="header-image" src="./assets/gameBox.png" />
        </div>
        <div class="header-title-right">
          <!-- <div class="heaeder-text" @click="conactAction()">Connect</div> -->
        </div>
      </div>
    </div>
    <div class="width100" v-if="pageIndex == 0">
      <img class="heaeder-text heaeder-text-1" src="./assets/game-logo.png" />
      <home-content></home-content>
      <video-content></video-content>
      <fght-content></fght-content>
      <div id="nfts">
        <nfts-content></nfts-content>
      </div>
    </div>
    <div class="width100" v-if="pageIndex == 2">
      <leaderboard-content></leaderboard-content>
    </div>
    <div class="width100" v-if="pageIndex == 5">
      <mint-nft></mint-nft>
    </div>
    <div class="width100" v-if="pageIndex == 3">
      <zengceBox></zengceBox>
    </div>
    <div class="width100" v-if="pageIndex == 4">
      <termsContent></termsContent>
    </div>
    <populeBox v-if="populeShow" @closeAction="closeAction"></populeBox>
    <footerContent @privacyAction="privacyAction" @termsAction="termsAction"></footerContent>
  </div>
</template>

<script>
import headerBox from "./components/headerBox.vue";
import homeContent from "./components/homeContent.vue";
import videoContent from "./components/videoContent.vue";
import fghtContent from "./components/fghtContent.vue";
import nftsContent from "./components/nftsContent.vue";
import footerContent from "./components/footerContent.vue";
import leaderboardContent from "./components/leaderboard.vue";
import mintNft from "./components/mintNft.vue";
import minBox from "./components/minBox.vue";
import populeBox from "./components/populeBox.vue";
import zengceBox from "./components/zengce.vue";
import termsContent from "./components/termsContent.vue";

export default {
  name: "App",
  components: {
    headerBox,
    homeContent,
    videoContent,
    fghtContent,
    nftsContent,
    footerContent,
    leaderboardContent,
    mintNft,
    minBox,
    populeBox,
    zengceBox,
    termsContent,
  },
  data() {
    return {
      pageIndex: 0,
      populeShow: false,
      headerList: [
        {
          title: "PLAY",
        },
        {
          title: "BLINKS",
        },
        // {
        //   title: "LEADERBOARD",
        // },
      ],
      currentIndex: 0,
    };
  },
  methods: {
    // 书
    xAction() {
      window.location.href = "https://x.com/poly_blink";
    },
    // 点击底部
    termsAction(index) {
      this.pageIndex = index;
    },
    // 点击底部隐私政策
    privacyAction(index) {
      this.pageIndex = index;
    },
    // 关闭
    closeAction() {
      this.populeShow = false;
    },
    // 右边的点击
    conactAction() {
      this.populeShow = true;
    },
    // 左边的点击
    arenaAction(index) {
      this.pageIndex = index;
      this.currentIndex = index;
    },
    qitaAction(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style>
.header-image-logo {
  width: 70px;
}

.polyBlink-title {
  font-size: 0.8rem;
  color: #D203FA;
  text-align: center;
  width: 70%;
  margin-left: 15%;
}

.polyBlink-title img {
  width: 100%;
}

.banner-box {
  width: 100%;
}

.width100 {
  width: 100%;
}

.page-container {
  width: 80%;
  margin-left: 10%;
}


.Play-ARENA {
  padding: 0.1rem 0.2rem;
  border-radius: 0.05rem;
  border: 1px #D203FA solid;
  color: #D203FA;
  margin-top: 0.3rem;
  display: inline-flex;
}

.Polyblink-box {
  white-space: nowrap;
  font-family: serif;
  font-size: 0.2rem;
}

.heaeder-text {
  margin-left: 0.2rem;
  font-size: 0.2rem;
  
  cursor: pointer;
}

.header-title-left {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.header-title-right {
  display: inline-flex;
  justify-content: flex-end;
}

.header-title {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 1fr;
  width: 100%;
  color: white;
  padding: 0.2rem 0;
}

.Mint-nft {
  background: #D203FA;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.05rem 0.2rem;
  white-space: nowrap;
}

.header-image {
  width: 0.2rem;
  margin-left: 0.2rem;
}

.heaeder-text-1 {
  margin-left: 30%;
  width: 40%;
}

a {
  color: white !important;
  text-decoration: none !important;
}

.Play-ARENA:hover {
  cursor: pointer;
  background-color: #D203FA;
  color: white;
}

.heaeder-text:hover {
  color: #D203FA !important;
}


@media screen and (max-width: 750px) {
  .page-container {
    position: fixed;
    overflow: auto;
    height: 100vh;
    width: 100% !important;
    margin-left: 0;
  }

  .header-title {
    width: 90%;
    margin-left: 5%;
  }

  .heaeder-text-1 {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 90%;
    margin-left: 5%;
  }

  ::v-deep .first-box {
    margin-left: 0;
  }

  .logo2-box {
    display: none;
  }

  .preview-box-content {
    width: 100% !important;
    margin-left: 5%;
    margin-top: 70px;
  }

  .Fight-box {
    display: block !important;
  }

  .Fight-box-right img {
    width: 100% !important;
    margin-top: 30px;
  }

  .DEGAMERS-title {
    width: 90%;
    margin-left: 5% !important;
    text-align: center;

  }

  .Publish-box {
    text-align: center !important;
    width: 100% !important;
    display: inline-flex !important;
    justify-content: center;
  }

  .DEGAMERS-title {
    width: 90% !important;
    margin-left: 5% !important;
  }

  .pc-lubo {
    display: block !important
  }

  .mobile-lubo {
    display: none !important
  }

 

  .degamex-footer{
    display: block !important;
    margin-left: 5% !important;
  }
}



.pc-lubo {
  display: none !important
}

.mobile-lubo {
  display: block !important
}
</style>
