<template>
  <div>
    <div class="polyBlink-box">PolyBlink Privacy Policy</div>
    <div class="our-box">
      Our Privacy Policy were last updated on 1st of November, 2023
    </div>
    <div class="our-box">
      DeGameX (“we”, “our”, or “us”) is committed to protecting your privacy.
      This Privacy Policy explains how your personal information is collected,
      used, and disclosed by DeGameX.
    </div>
    <div class="our-box">
      Please read this Privacy Policy carefully before using the DeGameX
      website. This Privacy Policy applies to our website and all other
      associated subdomains. Сompany incorporated in Singapore.
    </div>

    <div class="our-box">
      This Privacy Policy document contains the types of information that is
      collected and recorded by DeGameX and how we use it. In this Privacy
      Policy, by personal data, we mean information that identifies you as an
      individual. Personal data does not include non-personally identifying
      information. The Privacy Policy applies only to our online activities and
      is valid for visitors to our website with regard to the information that
      they share and/or collect in DeGameX. This policy does not apply to any
      information collected offline or via channels other than this website.
    </div>

    <div class="our-box">Consent</div>

    <div class="our-box">
      By using our service, you hereby consent to our Privacy Policy and agree
      to its terms.
    </div>

    <div class="our-box">Non-personally identifying information</div>

    <div class="our-box">
      Our purpose in collecting non-personally identifying information is to
      better understand how visitors use our website. We do not use
      non-personally identifying information in a way that would enable us to
      identify you personally.
    </div>

    <div class="our-box">Definitions and Terms</div>

    <div class="our-box">
      To help explain things as clearly as possible in this Privacy Policy,
      every time any of these terms are referenced, are strictly defined as:
    </div>

    <div class="our-box">
      - Cookie: a small amount of data generated by a website and saved by your
      web browser. It is used to identify your browser, provide analytics,
      remember information about you such as your language preference or login
      information;
      <div class="our-box">
        - User: refers to the company, organization, or person that using the
        website;
      </div>

      <div class="our-box">
        - Device: any internet-connected device such as a phone, tablet,
        computer, or any other device that can be used to visit DeGameX;
      </div>

      <div class="our-box">
        - Do Not Track (DNT): is a concept that has been promoted by US
        regulatory authorities, in particular the U.S. Federal Trade Commission
        (FTC), for the Internet industry to develop and implement a mechanism
        for allowing Internet users to control the tracking of their online
        activities across websites.
      </div>

      <div class="our-box">
        - IP address: every device connected to the Internet is assigned a
        number known as an Internet protocol (IP) address. These numbers are
        usually assigned in geographic blocks. An IP address can often be used
        to identify the location from which a device is connecting to the
        Internet;
      </div>

      <div class="our-box">
        - Personal Data: any information that directly, indirectly, or in
        connection with other information, including a personal identification
        number, allows for the identification or identifiability of a natural
        person;
      </div>

      <div class="our-box">
        - Third-party service: refers to advertisers, contest sponsors,
        promotional and marketing partners and others who provide our content or
        whose products or services we think may interest you;
      </div>

      <div class="our-box">
        - You: a person or entity that is connected with DeGAmeX to use the
        website.
      </div>

      <div class="our-box">Cookies</div>
      <div class="our-box">
        Cookies are text files placed on your computer to collect standard
        Internet log information and visitor behavior information. We use
        cookies to help us track your usage of our website and your website
        access preferences.
      </div>

      <div class="our-box">
        We use cookies to enhance the performance and functionality of our
        website. They are completely safe and can't be used to run programs or
        deliver viruses to your device. We never place personally identifiable
        information in Cookies.
      </div>

      <div class="our-box">
        Cookies can either be session Cookies or persistent Cookies. A session
        Cookie expires automatically when you close your browser. A persistent
        Cookie will remain until it expires or you delete your Cookies.
        Expiration dates are set in the Cookies themselves; some may expire
        after a few minutes while others may expire after multiple years.
      </div>

      <div class="our-box">
        We use essential Cookies to make our website work. These Cookies are
        strictly necessary to enable core functionality such as security,
        network management, your Cookie preferences, and accessibility. You may
        disable these by changing your browser settings, but this may affect how
        the website functions.
      </div>
      <div class="our-box">
        If you do not wish to have cookies placed on your computer, you may set
        your browser to refuse cookies before using our websites, with the
        caveat that certain features of our websites may not function properly
        without the aid of cookies.
      </div>
      <div class="our-box">
        By linking your wallet, you consent to the use of your cookies.
      </div>
      <div class="our-box">Personal data</div>
      <div class="our-box">
        You may choose to interact with the website in ways that require us to
        gather your data. The amount and type of personal data that we gather
        depends on the nature of the interaction. You may always refuse to
        supply personal data, with the caveat that it may prevent you from using
        certain parts of the website.
      </div>
      <div class="our-box">Personal data includes:</div>
      <div class="our-box">
        Interaction information: wallet addresses, twitter account, discord
        account (username, ID), telegram account.
      </div>
      <div class="our-box">Use of personal data</div>
      <div class="our-box">
        We use the information we collect in various ways, including:
      </div>
      <div class="our-box">
        - Provide, operate, and maintain our website and gameplay function
        (playbot)
      </div>
      <div class="our-box">- Improve, personalize, and expand our website</div>
      <div class="our-box">
        - Understand and analyze how you use our website
      </div>
      <div class="our-box">
        - Develop new products, services, features, and functionalities
      </div>
      <div class="our-box">- Find and prevent fraud</div>
      <div class="our-box">How Do We Protect Your Information?</div>
      <div class="our-box">
        We implement a variety of security measures to maintain the safety of
        your personal information when you place an order or enter, submit, or
        access your personal information. We offer the use of a secure server.
        We cannot, however, ensure or warrant the absolute security of any
        information you transmit to Us or guarantee that your information on the
        website may not be accessed, disclosed, altered, or destroyed by a
        breach of any of our physical, technical, or managerial safeguards.
      </div>
      <div class="our-box">Third-Party Services</div>
      <div class="our-box">
        We may display, include, or make available third-party content
        (including data, information, applications, and other product services)
        or provide links to third-party websites or services ("Third-Party
        Services").
      </div>
      <div class="our-box">
        You acknowledge and agree that DeGameXshall not be responsible for any
        Third-Party Services, including their accuracy, completeness,
        timeliness, validity, copyright compliance, legality, decency, quality,
        or any other aspect thereof. DeGameX does not assume and shall not have
        any liability or responsibility to you or any other person or entity for
        any Third-Party Services.
      </div>
      <div class="our-box">
        Third-party services and links thereto are provided solely as a
        convenience to you and you access and use them entirely at your own risk
        and subject to such third parties' terms and conditions.
      </div>
      <div class="our-box">Local Storage:</div>
      <div class="our-box">
        Local Storage, sometimes known as DOM storage, provides web apps with
        methods and protocols for storing client-side data. Web storage supports
        persistent data storage, similar to Cookies but with a greatly enhanced
        capacity and no information stored in the HTTP request header.
      </div>
      <div class="our-box">Sessions:</div>
      <div class="our-box">
        We use sessions to identify the areas of our platform that you have
        visited. A session is a small piece of data stored on your computer or
        mobile device by your web browser.
      </div>
      <div class="our-box">Privacy policy changes</div>
      <div class="our-box">
        These Terms and Conditions may be amended, changed, or updated by
        DeGameX at any time and without prior notice to you. You should check
        back often to confirm that your copy and understanding of these Terms
        and Conditions are current and correct.
      </div>
      <div class="our-box">Contact Us</div>
      <div class="our-box">
        For further inquiries or complaints about our handling of your Personal
        Data or our Privacy Policy or wish to access, update, or amend your
        Personal Data as mentioned above, please contact us via email:
        info@degamex.com.
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
  .our-box{margin: 0.2rem 0;color: white;font-size: 0.2rem;}
  .polyBlink-box{color: #D203FA;font-size: 0.4rem;margin: 1rem 0;}
</style>
