<template>
  <div class="Leaderboard">
    <div class="Leaderboard-form-title">Leaderboard</div>
    <div class="Leaderboard-form">
        <div class="Leaderboard-title">
            <div class="Leaderboard-title-left">
              <div>Rank</div>
              <div class="User-box">User</div>
            </div>
            <div class="Leaderboard-title-right">
              <div>Epoch 2 Points</div>
            </div>
        </div>
        <div class="Leaderboard-content">
          <div class="Leaderboard-content-box" v-for="(item,index) in 14" :key="index"></div>
          <div class="Prev-Next">
            <div class="Prev-box">Prev</div>
            <div class="Prev-box">Next</div>

          </div>
        </div>
    </div>
  </div>
</template>

<script setup>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Prev-box{
  background-color: #161617;
  color: #D203FA;
  padding: 0.1rem;
  font-size: 0.1rem;
}

.Prev-box:nth-of-type(2){
  margin-left: 0.1rem;
}

.Prev-Next{
  display: inline-flex;
  width: 100%;
  justify-content: center;
  margin-top: 0.2rem;
}
.Leaderboard-content-box{
  background-color: #141415;
  width: 90%;
  margin-left: 5%;
  padding: 0.15rem 0;
  margin-top: 0.1rem;
}

.Leaderboard-content-box:first-of-type{
  margin-top: 0;
}

.Leaderboard-content{
  margin-top: 0.2rem;
}
.User-box{
  margin-left: 0.3rem;
}
.Leaderboard-title-left{
  display: inline-flex;
  align-content: center;
}

.Leaderboard-title-right{
  display: inline-flex;
  justify-content: flex-end;
}
.Leaderboard-title{
  display: grid;
  grid-template-columns: 50% 1fr;
  width: 90%;
  margin-left: 5%;
}
.Leaderboard{
  color: #D203FA;
  font-size: 0.2rem;
}

.Leaderboard-form{
  background-color: #19191A;
  border-radius: 0.2rem;
  width: 60%;
  margin-left: 20%;
  margin-top: 0.4rem;
  padding: 0.3rem 0;
  margin-bottom: 0.5rem;
}

.Leaderboard-form-title{
  width: 60%;
  margin-left: 20%;
  text-align: center;
}
</style>
