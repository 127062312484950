<template>
  <div class="degamex-footer">
    <div class="Terms-box">© 2024 polyblink2024@gmail.com</div>
    <div class="Terms-box">
      <img class="header-image" @click="xAction" src="../assets/xBox.png" />
    </div>
    <div class="Terms-box">
      <img class="header-image" src="../assets/gameBox.png" />
    </div>
    <div class="Terms-box" @click="privacyAction()">Privacy Policy</div>
    <div class="Terms-box" @click="termsAction()">Terms of service</div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { defineEmits } from "vue";
// 书
const xAction = () => {
  window.location.href = "https://x.com/poly_blink";
};

const emit = defineEmits(["updateData"]);

const privacyAction = () => {
  emit("privacyAction", 3);
};

const termsAction = () => {
  emit("termsAction", 4);
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Terms-box:hover {
  color: #D203FA;
}
.Terms-box {
  cursor: pointer;
}
.Terms-box img {
  width: 0.2rem;
}
.degamex-footer {
  display: inline-flex;
  align-items: center;
  color: white;
  width: 70%;
  margin-left: 15%;
  font-size: 0.2rem;
  justify-content: center;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
}

.Terms-box {
  margin-left: 0.2rem;
}
</style>
