<template>
  <div class="header-title">
    <div class="header-title-left">
      <img class="heaeder-text heaeder-text-1" src="../assets/game-logo.png" />
      <!-- <div class="Polyblink-box">PolyBlink</div> -->
      <div
        @click="arenaAction(index)"
        v-for="(item, index) in headerList"
        :key="index"
        :class="{ 'Mint-nft': currentIndex == index }"
        class="heaeder-text"
      >
         <a href="#nfts" v-if="currentIndex==1"> {{ item.title }}</a>
      </div>
      <img class="header-image" @click="xAction" src="../assets/xBox.png" />
      <img class="header-image" src="../assets/gameBox.png" />
    </div>
    <div class="header-title-right">
      <div class="heaeder-text" @click="conactAction()">Connect</div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick } from "vue";
import { defineEmits } from "vue";

const emit = defineEmits(["updateData"]);
const headerList = ref();
const currentIndex = ref(0);
const xAction = () => {
  window.location.href = "https://x.com/poly_blink";
};
headerList.value = [
  {
    title: "PLAY",
  },
  {
    title: "BLINKS",
  },
  {
    title: "LEADERBOARD",
  },
];
const arenaAction = (index) => {
  currentIndex.value = index;
 
  emit("arenaAction", index);
};

const conactAction = () => {
  emit("conactAction");
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.Polyblink-box {
  white-space: nowrap;
  font-family: serif;
  font-size: 0.2rem;
}
.heaeder-text {
  margin-left: 0.2rem;
  font-size: 0.2rem;
  
  cursor: pointer;
}
.header-title-left {
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.header-title-right {
  display: inline-flex;
  justify-content: flex-end;
}
.header-title {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 1fr;
  width: 100%;
  color: white;
  padding: 0.2rem 0;
}

.Mint-nft {
  background: #D203FA;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.05rem 0.2rem;
  white-space: nowrap;
}

.header-image {
  width: 0.2rem;
  margin-left: 0.2rem;
}

.heaeder-text-1 {
  margin-left: 0;
  width: 0.7rem;
}
</style>
