<template>
  <div class="Base-box">
    <div
      class="Base-box-content"
      v-for="(item, index) in dataList"
      :key="index"
    >
      <div></div>
      <div class="title-box">{{ item.title }}</div>
      <div class="Base-image">
        <img :src="item.img" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick } from "vue";
// import { onLoad, onShow } from "@dcloudio/uni-app";

const dataList = ref();
const created = () => {
  dataList.value = [
    {
      title: "Base",
      img: "https://degamex.com/assets/common-378e15d0.svg",
    },
    {
      title: "Rare",
      img: "https://degamex.com/assets/rare-dbf93e00.svg",
    },
    {
      title: "Epic",
      img: "https://degamex.com/assets/epic-4a3dc306.svg",
    },
  ];
};

created();
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title-box{
  text-align: center;
  margin-bottom: 0.1rem;
  font-size: 0.25rem;
  

}

</style>
